import { FormGroupBase } from '../components/FormGroupBase'

/**
 * Form Builder factory.  Base FormBuilder
 * @param {object} rawFormControls - FormControls
 * @returns {object} ExtendedFormGroup - Mixin
 */
const FormBuilder = (rawFormControls) => {
  const formGroup = FormGroupBase(rawFormControls)

  return {
    computed: {
      ...formGroup.computed,
    },
    data() {
      return {
        ...formGroup.data,
      }
    },
    methods: {
      ...formGroup.methods,
    },
    unmounted() {
      const controlKeys = Object.keys(rawFormControls)

      for (const controlName of controlKeys) {
        this[`${controlName}Reset`]()
      }
    },
    watch: {
      ...formGroup.watch,
    },
  }
}

export { FormBuilder }
