const validResponse = {
  errors: [],
  valid: true,
}

const urlRegex = /^[a-zA-Z0-9_-]*$/

const urlSafeSlug = (valueToValidate) => {
  if (!valueToValidate) {
    return validResponse
  }

  if (!urlRegex.test(valueToValidate)) {
    return {
      errors: ['Only letters, numbers, dashes, or underscores allowed'],
      valid: false,
    }
  }

  return validResponse
}

export { urlSafeSlug }
