/**
 * Validates any value as required
 *
 * Return Validation Result:
 * {
 * valid: Boolean,
 * error: String | null
 * }
 * @param {*} valueToValidate Value to determine if exists
 * @returns {object} Validation Result
 */
const required = (valueToValidate) => {
  const invalidResponse = {
    errors: ['Field is required'],
    valid: false,
  }

  if (valueToValidate === null) {
    return invalidResponse
  }

  if (valueToValidate === undefined) {
    return invalidResponse
  }

  if (valueToValidate === '') {
    return invalidResponse
  }

  return {
    errors: [],
    valid: true,
  }
}

export { required }
