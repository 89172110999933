import { FormControlBase } from '../components/FormControlBase'

const reduceFormControls = (formControlArray) => {
  return formControlArray.reduce((acc, curr) => {
    return {
      ...acc,
      ...curr,
    }
  }, {})
}

/**
 * Form Group Object
 * @param {object} rawFormControls Object of form control objects
 * @param {Function} updateModel Triggers Form Controls to update model
 * @returns {object} object representation of mixin for reuse
 */
const FormGroupBase = (rawFormControls, updateModel = false) => {
  const formControlNames = Object.keys(rawFormControls)

  const formControls = formControlNames.map((formControlName) =>
    FormControlBase(
      {
        ...rawFormControls[formControlName],
        name: formControlName,
      },
      updateModel
    )
  )

  return {
    computed: {
      $_fgb_instanceFormControls() {
        return formControlNames.map((name) => ({ ...this[name], name }))
      },
      formErrors() {
        const { $_fgb_instanceFormControls } = this

        return $_fgb_instanceFormControls.flatMap((control) =>
          control.status.errors.map((error) => `${control.name}::${error}`)
        )
      },
      formValues() {
        return formControlNames.reduce((acc, curr) => {
          return {
            ...acc,
            [curr]: this[curr].value,
          }
        }, {})
      },
      isFormValid() {
        const { $_fgb_instanceFormControls } = this

        const formControlStatus = $_fgb_instanceFormControls.map(
          (control) => control.status.valid
        )

        return formControlStatus.every((status) => status === true)
      },
    },
    data: {
      ...reduceFormControls(formControls.flatMap((control) => control.data)),
      // True when in the process of setting initial values
      $_fgb_settingInitialValues: false,
    },
    methods: {
      ...reduceFormControls(formControls.flatMap((control) => control.methods)),
      $_fgb_onFormControlUpdate() {
        const { formValues, isFormValid } = this

        if (this.onFormValuesUpdate) {
          this.onFormValuesUpdate(formValues, isFormValid)
        }
      },
      resetForm() {
        for (const controlName of formControlNames) {
          this[`${controlName}Reset`]()
        }
      },
      /**
       * Set initial form values imperatively, e.g. in a `created` callback, without triggering a redundant
       * `onFormControlUpdate`/`onFormValuesUpdate`.
       * @param {object} formValues Map of form control names to initial values
       */
      setInitialFormValues(formValues) {
        this.$_fgb_settingInitialValues = true
        for (const [controlName, initialValue] of Object.entries(formValues)) {
          if (controlName in this) {
            // Prevents setting `.value` if the key is mounted but not controlled by FormBuilder
            if (typeof this[controlName] === 'object') {
              this[controlName].value = initialValue
            }
          }
        }
        // eslint-disable-next-line no-return-assign
        this.$nextTick(() => (this.$_fgb_settingInitialValues = false))
      },
    },
    watch: {
      ...reduceFormControls(formControls.flatMap((control) => control.watch)),
    },
  }
}

export { FormGroupBase }
