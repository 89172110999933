/**
 * Composes multiple validators into a single validation function
 * @param {Array} validators Array of validators
 * @returns {Function} Composed Validation Function
 */
const compose = (validators) => {
  return (valueToValidate) => {
    const results = []

    validators.forEach((validateFn) => {
      results.push(validateFn(valueToValidate))
    })

    const validResults = results.flatMap((result) => result.valid)
    const messages = results.flatMap((result) => result.errors)

    return {
      errors: messages.filter((message) => message),
      valid: validResults.every((result) => result === true),
    }
  }
}

export { compose }
