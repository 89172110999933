/**
 * Validates if string is an color
 *
 * Return Validation Result:
 * {
 * valid: Boolean,
 * error: String | null
 * }
 * @param {string} valueToValidate String To Determine If color
 * @returns {object} Validation Result
 */
const color = (valueToValidate) => {
  if (
    !valueToValidate ||
    valueToValidate.length < 7 ||
    valueToValidate.length > 7
  ) {
    return {
      errors: ['Must contain 7 characters'],
      valid: false,
    }
  }

  if (!valueToValidate.startsWith('#')) {
    return {
      errors: ['Must start with #'],
      valid: false,
    }
  }

  const re = /^[0-9A-F]+$/

  if (!re.test(valueToValidate.slice(1))) {
    return {
      errors: ['Must only contain 0-9 and A-F'],
      valid: false,
    }
  }

  return {
    errors: [],
    valid: true,
  }
}

export { color }
