import bus from '@/mixins/bus'

/**
 * Triggers a snackbar notification using the `$notify` method
 * @mixin
 */
export default {
  name: 'Notify',
  mixins: [bus],
  methods: {
    /**
     * Triggers an app-level snackbar notification
     * @param {string|object} message Described by `components/Root/NoficiationDialog`
     * @param {"error"|"success"|"info"|"warning"} mode The styling to use for the notification, deafults to "Info"
     * @param {object} options Other options passed to the notification
     * @param {Function} options.onClose The callbacak when the close action is clicked
     * @param {string} options.buttonText The close button's text, defaults to close
     * @public
     */
    $notify(message, mode = 'info', options = {}) {
      this.$bus.emit('root-notification', { mode, text: message, ...options })
    },
  },
}
