import isURL from 'validator/lib/isURL'

/**
 * Validates any value as a url
 *
 * Return Validation Result:
 * {
 * valid: Boolean,
 * error: String | null
 * }
 * @param {*} valueToValidate Value to determine if exists
 * @returns {object} Validation Result
 */
const url = (valueToValidate) => {
  const validResponse = {
    errors: [],
    valid: true,
  }

  if (!valueToValidate) {
    return validResponse
  }

  if (!isURL(valueToValidate)) {
    return {
      errors: ['Must be URL'],
      valid: false,
    }
  }

  if (
    !isURL(valueToValidate, {
      protocols: ['https'],
      require_protocol: true,
      require_valid_protocol: true,
    })
  ) {
    return {
      errors: ['URL must start with https://'],
      valid: false,
    }
  }

  return validResponse
}

export { url }
