/**
 * Validates if string is less than max length
 *
 * Return Validation Result:
 * {
 * valid: Boolean,
 * error: String | null
 * }
 * @param {number} maxLength Number of chars
 * @returns {Function} Validation Function
 */
const maxLength = (maxLength) => (valueToValidate) => {
  if (valueToValidate && valueToValidate.length > maxLength) {
    return {
      errors: [`Text cannot exceed ${maxLength} characters`],
      valid: false,
    }
  }

  return {
    errors: [],
    valid: true,
  }
}

export { maxLength }
