/**
 * Validates if string is an email
 *
 * Return Validation Result:
 * {
 * valid: Boolean,
 * error: String | null
 * }
 * @param {string} valueToValidate String To Determine If Email
 * @returns {object} Validation Result
 */
const email = (valueToValidate) => {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

  if (!re.test(valueToValidate)) {
    return {
      errors: ['Field must be a valid email address'],
      valid: false,
    }
  }
  return {
    errors: [],
    valid: true,
  }
}

export { email }
