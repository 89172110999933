import debounce from 'lodash/debounce'

import { Validators } from '@/forms'

/**
 * Form control Object
 *
 * Form Control:
 * {
 * initialValue: *,
 * validators: Validator[],
 * name: String // Field Name
 * }
 * @param {object} formControl Form control Object
 * @param {boolean} updateModel update model
 * @returns {object} object representation of mixin for reuse
 */
const FormControlBase = (formControl, updateModel = false) => {
  const validator = Validators.compose(formControl.validators)

  const debounceTime = formControl.debounce ?? 0
  const initialValue = formControl.initialValue

  const defaultValidator = {
    errors: [],
    valid: undefined,
  }

  return {
    data: {
      [formControl.name]: {
        status: formControl.validateOnInit
          ? validator(initialValue)
          : defaultValidator,
        validator,
        value: initialValue,
      },
    },
    methods: {
      [`${formControl.name}Reset`]() {
        this[formControl.name].status = formControl.validateOnInit
          ? validator(initialValue)
          : defaultValidator
        this[formControl.name].value = initialValue
      },
      onFormControlUpdateDebounced: debounce(function (...args) {
        if (this.onFormControlUpdate) {
          this.onFormControlUpdate(...args)
        }

        this.$_fgb_onFormControlUpdate()
      }, debounceTime),
    },
    watch: {
      [`${formControl.name}.value`](newVal) {
        // Validate when a) not setting initial values or b) setting initial values and `validateOnInit` is enabled
        if (
          !this.$_fgb_settingInitialValues ||
          (this.$_fgb_settingInitialValues === true &&
            formControl.validateOnInit)
        ) {
          this[formControl.name].status =
            this[formControl.name].validator(newVal)
        }

        // When setting initial values, skip updates because they would be redundant
        if (!this.$_fgb_settingInitialValues) {
          this.onFormControlUpdateDebounced(
            formControl.name,
            newVal,
            this[formControl.name].status
          )
        }

        if (updateModel) {
          this.$_fbms_update_model()
          this.$_fbms_update_valid()
          this.$_fbms_update_errors()
        }
      },
    },
  }
}

export { FormControlBase }
